<template>
  <div class="test">
    <!-- <p>{{greeting}} World!</p> -->
    <p>{{greeting}} World!</p>
    <ul>
      <li v-for="color in colorList" :key="color">{{color}}</li>
    </ul>
    <button v-on:click="vib()">Vibrate</button>
  </div>
</template>
<script>
export default {
  name: 'Test123-22',
  props: {
    greeting: String,
  },
  data() {
    return {
      colorList: [
        'Red',
        'Green'
      ],
    }
  },
  methods: {
    vib:function() {
      // alert("a");
      navigator.vibrate([
        100,30,
        100,30,
        100,30,
        200,30,
        200,30,
        200,30,
        100,30,
        100,30,
        100,30]);
    },
  },
}
// var a = new Vue({
//   el: 'ul',
//   data: {
//   },
// });

</script>

<style scoped lang="scss">
.test {
  border-top: 1px black dotted;
  margin-bottom: 10rem;
  p {
    font-size: 2em;
    text-align: center;
  }
  ul {
    width: 33%;
    margin-left: auto;
    margin-right: auto;
    li {
      text-align: left;
    }
  }
}
</style>