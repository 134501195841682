<template>
  <div id="header">
    <h1>{{title}}</h1>
    <div class="buttons">
      <button class="btn btn-red" v-on:click="logout">ログアウト</button>
    </div>
    <div class="mt4id">
    MT4ID: {{mt4id}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'VPSHeader',
  props: {
    title: String,
    mt4id: String,
  },
  methods: {
    logout: function() {
      alert("logout");
    },
  }
}
</script>

<style scoped lang="scss">
#header {
  // background-image: url('@/assets/test.jpg');
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  h1 {
    width: 60%;
    margin: 0;
    text-align: left;
  }
  .buttons {
    width: 40%;
    text-align: right;
    // background-color: red;
  }
  .mt4id {
    width: 100%;
    text-align: right;
    font-weight: 600;
    font-size: 20px;
  }
}
.btn {
  border-radius: 20px;
  border: 0px;
  padding: 0.33rem 0.75rem;
  appearance: none;
  color: white;
  font-size: 18px;
  font-family: "Hiragino Sans";
  font-weight: 500;
  // backdrop-filter: blur(12px);
  transition: all 0.1s;
  &:active {
    filter: brightness(0.9);
  }
}
.btn-red {
  background-color: #FF3B30;
}

.btn-blue {
  background-color: rgba(0, 122, 255, 1);
}
</style>
