<template>
  <VPSHeader title="VPS管理" mt4id="23355009"/>
  <img alt="Vue logo" src="./assets/logo.png">
  <HelloWorld msg="Welcome to Your Vue.js App"/>
  <Test greeting="Hello"/>
</template>

<script>
import VPSHeader from './components/VPSHeader.vue'
import HelloWorld from './components/HelloWorld.vue'
import Test from './components/Test.vue'
export default {
  name: 'App',
  components: {
    VPSHeader,
    HelloWorld,
    Test,
  },
}
</script>

<style>
#app {
  font-family: "Hiragino Sans", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
